/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://y434fpezcnaaxp5aktfh3nx5fa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "us-east-1:134a32eb-d792-473c-af7f-5c196e70683e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1NtZLIZjG",
    "aws_user_pools_web_client_id": "54hlmtdf4u3s4b2td6p4sff0ec",
    "oauth": {},
    "aws_user_files_s3_bucket": "invisible-library-storage180627-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
